.ce-paragraph a {
  text-decoration: underline !important;
  background-color: transparent !important;
  border-bottom: 0 !important;
}
.ce-block__content,
.ce-toolbar__content {
  width: 100%;
  max-width: unset;
}
.ce-toolbar__actions {
  right: calc(100% + 4px);
}
.ce-toolbar__plus,
.ce-toolbar__settings-btn {
  width: 24px;
  height: 24px;
}
.ce-block:hover,
.ce-block--focused {
  background-color: #fbfbfb;
}

/* embed video */
.embed-tool__content,
.embed-tool__caption {
  max-width: 100%;
  width: 640px !important;
}
.embed-tool__content {
  height: 360px !important;
  margin: 0 auto;
}
.embed-tool__caption {
  margin: 8px auto;
}
@media screen and (min-width: 1024px) {
  .embed-tool__content,
  .embed-tool__caption {
    width: 854px !important;
  }
  .embed-tool__content {
    height: 480px !important;
  }
}
/* end embed video */

/* upload video */
.video-tool__video {
  border: 0 !important;
  background-color: unset !important;
}
.video-tool__video > div,
.video-tool__caption {
  max-width: 100%;
  width: 640px !important;
}
.video-tool__video > div {
  height: 360px !important;
  margin: 0 auto;
  background-color: #000;
}
.video-tool__caption {
  display: block !important;
  margin: 8px auto;
}
.video-tool--stretched .video-tool__video > div {
  width: 100% !important;
  height: auto !important;
}
.video-tool--stretched .video-tool__caption {
  width: 100% !important;
}
@media screen and (min-width: 1024px) {
  .video-tool__video > div,
  .video-tool__caption {
    width: 854px !important;
  }
  .video-tool__video > div {
    height: 480px !important;
  }
}
/* end upload video */

/* image */
.image-tool {
  max-width: 100%;
  width: 854px;
  margin: 4px auto;
}
.image-tool--withBackground {
  width: 100%;
}
.image-tool--withBackground .image-tool__image-picture {
  max-width: 100%;
  width: 854px;
}
.image-tool--stretched .image-tool__image {
  padding: 0;
}
.image-tool--stretched,
.image-tool--stretched .image-tool__image-picture {
  width: 100%;
}
.image-tool__image-picture {
  margin: 0 auto;
}
/* end image */

/* simple image */
.cdx-simple-image__picture {
  max-width: 100%;
  width: 854px;
  margin: 4px auto;
}
.cdx-simple-image__picture img {
  width: 100%;
}
.cdx-simple-image__picture + .cdx-simple-image__caption {
  width: 854px;
  margin: 0 auto;
}
.cdx-simple-image__picture--stretched,
.cdx-simple-image__picture--stretched + .cdx-simple-image__caption,
.cdx-simple-image__picture--with-background,
.cdx-simple-image__picture--with-background + .cdx-simple-image__caption {
  width: 100% !important;
}
.cdx-simple-image__picture--with-background {
  padding: 15px;
}
.cdx-simple-image__picture--with-background img {
  max-width: 100%;
  width: 854px;
}
.cdx-simple-image__picture--stretched img {
  width: 100%;
}
/* end simple image */

/* columns */
.ce-editorjsColumns_col {
  padding: 16px;
  border: 1px solid #eee;
}
.ce-editorjsColumns_col .image-tool {
  width: 100%;
  margin: 0 auto;
  padding: 0;
}
.ce-editorjsColumns_col .image-tool__image {
  aspect-ratio: 1 / 0.6;
  background-color: #f6f6f6;
}
.ce-editorjsColumns_col .image-tool__image-picture {
  width: auto;
  height: 100%;
  object-fit: cover;
}
.ce-editorjsColumns_col .image-tool--withBackground .image-tool__image {
  padding: 0;
}
.ce-editorjsColumns_col:nth-child(1) {
  z-index: 3;
}
.ce-editorjsColumns_col:nth-child(2) {
  z-index: 2;
}
.ce-editorjsColumns_col:nth-child(3) {
  z-index: 1;
}
/* end columns */

/* button */
.button-tool {
  text-decoration: none;
  padding-left: 16px;
  padding-right: 16px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: white !important;
  font-size: 1.3rem;
  background-color: #00a0d4 !important;
}
.button-tool:hover {
  background-color: #80bfe4 !important;
}

.alignment-wrapper {
  border: 1px solid #ddd;
  border-radius: 4px;
}
.alignment-button {
  width: 60px;
  cursor: pointer;
  padding: 2px 4px;
  text-align: center;
  font-size: 13px;
  border-right: 1px solid #ddd;
}
.alignment-button:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.alignment-button:last-child {
  border-right: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.alignment-button.active {
  background-color: #ddd;
}
.anyButtonContainer__registerButton {
  width: 100px;
  height: 32px;
  margin: 0;
  padding: 0;
  margin: 0 auto;
  font-size: 13px;
}
.anyButtonContainer__input {
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 13px;
}
/* end button */
