/*
@tailwind base;

@tailwind components;
*/

.space-x-8 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(0.8rem * var(--space-x-reverse));
  margin-left: calc(0.8rem * calc(1 - var(--space-x-reverse)))
}

.bg-black {
  --bg-opacity: 1;
  background-color: #22292F;
  background-color: rgba(34, 41, 47, var(--bg-opacity))
}

.bg-white {
  --bg-opacity: 1;
  background-color: #FFF;
  background-color: rgba(255, 255, 255, var(--bg-opacity))
}

.bg-paper {
  --bg-opacity: 1;
  background-color: #F7F7F7;
  background-color: rgba(247, 247, 247, var(--bg-opacity))
}

.bg-primaryLight {
  --bg-opacity: 1;
  background-color: #80BFE4;
  background-color: rgba(128, 191, 228, var(--bg-opacity))
}

.bg-secondaryMain {
  --bg-opacity: 1;
  background-color: #1F242F;
  background-color: rgba(31, 36, 47, var(--bg-opacity))
}

.bg-warning {
  --bg-opacity: 1;
  background-color: #F5D02F;
  background-color: rgba(245, 208, 47, var(--bg-opacity))
}

.bg-grey-50 {
  --bg-opacity: 1;
  background-color: #FAFAFA;
  background-color: rgba(250, 250, 250, var(--bg-opacity))
}

.bg-grey-200 {
  --bg-opacity: 1;
  background-color: #EEEEEE;
  background-color: rgba(238, 238, 238, var(--bg-opacity))
}

.bg-gray-100 {
  --bg-opacity: 1;
  background-color: #F5F5F5;
  background-color: rgba(245, 245, 245, var(--bg-opacity))
}

.bg-gray-200 {
  --bg-opacity: 1;
  background-color: #EEEEEE;
  background-color: rgba(238, 238, 238, var(--bg-opacity))
}

.bg-gray-300 {
  --bg-opacity: 1;
  background-color: #E0E0E0;
  background-color: rgba(224, 224, 224, var(--bg-opacity))
}

.hover\:bg-transparent:hover {
  background-color: transparent
}

.hover\:bg-grey-200:hover {
  --bg-opacity: 1;
  background-color: #EEEEEE;
  background-color: rgba(238, 238, 238, var(--bg-opacity))
}

.hover\:bg-gray-100:hover {
  --bg-opacity: 1;
  background-color: #F5F5F5;
  background-color: rgba(245, 245, 245, var(--bg-opacity))
}

.hover\:bg-gray-200:hover {
  --bg-opacity: 1;
  background-color: #EEEEEE;
  background-color: rgba(238, 238, 238, var(--bg-opacity))
}

.hover\:bg-gray-500:hover {
  --bg-opacity: 1;
  background-color: #9E9E9E;
  background-color: rgba(158, 158, 158, var(--bg-opacity))
}

.hover\:bg-blue-gray-100:hover {
  --bg-opacity: 1;
  background-color: #CFD8DC;
  background-color: rgba(207, 216, 220, var(--bg-opacity))
}

.focus\:bg-transparent:focus {
  background-color: transparent
}

.bg-opacity-50 {
  --bg-opacity: 0.5
}

.bg-opacity-75 {
  --bg-opacity: 0.75
}

.hover\:bg-opacity-50:hover {
  --bg-opacity: 0.5
}

.hover\:bg-opacity-100:hover {
  --bg-opacity: 1
}

.border-collapse {
  border-collapse: collapse
}

.border-white {
  --border-opacity: 1;
  border-color: #FFF;
  border-color: rgba(255, 255, 255, var(--border-opacity))
}

.border-gray-200 {
  --border-opacity: 1;
  border-color: #EEEEEE;
  border-color: rgba(238, 238, 238, var(--border-opacity))
}

.border-gray-300 {
  --border-opacity: 1;
  border-color: #E0E0E0;
  border-color: rgba(224, 224, 224, var(--border-opacity))
}

.border-red {
  --border-opacity: 1;
  border-color: #F44336;
  border-color: rgba(244, 67, 54, var(--border-opacity))
}

.border-yellow {
  --border-opacity: 1;
  border-color: #FFEB3B;
  border-color: rgba(255, 235, 59, var(--border-opacity))
}

.border-green {
  --border-opacity: 1;
  border-color: #4CAF50;
  border-color: rgba(76, 175, 80, var(--border-opacity))
}

.rounded-4 {
  border-radius: .4rem
}

.rounded-6 {
  border-radius: .6rem
}

.rounded-8 {
  border-radius: .8rem
}

.rounded-16 {
  border-radius: 1.6rem
}

.rounded-24 {
  border-radius: 2.4rem
}

.rounded-32 {
  border-radius: 3.2rem
}

.rounded-none {
  border-radius: 0
}

.rounded {
  border-radius: .4rem
}

.rounded-lg {
  border-radius: .8rem
}

.rounded-full {
  border-radius: 9999px
}

.rounded-t-24 {
  border-top-left-radius: 2.4rem;
  border-top-right-radius: 2.4rem
}

.border-0 {
  border-width: 0
}

.border-1 {
  border-width: 1px
}

.border-2 {
  border-width: 2px
}

.border {
  border-width: 1px
}

.border-b-1 {
  border-bottom-width: 1px
}

.border-t-2 {
  border-top-width: 2px
}

.border-b-2 {
  border-bottom-width: 2px
}

.border-b {
  border-bottom-width: 1px
}

.cursor-default {
  cursor: default
}

.cursor-pointer {
  cursor: pointer
}

.block {
  display: block
}

.inline-block {
  display: inline-block
}

.flex {
  display: flex
}

.inline-flex {
  display: inline-flex
}

.table {
  display: table
}

.hidden {
  display: none
}

.flex-row {
  flex-direction: row
}

.flex-row-reverse {
  flex-direction: row-reverse
}

.flex-col {
  flex-direction: column
}

.flex-wrap {
  flex-wrap: wrap
}

.items-start {
  align-items: flex-start
}

.items-end {
  align-items: flex-end
}

.items-center {
  align-items: center
}

.justify-start {
  justify-content: flex-start
}

.justify-end {
  justify-content: flex-end
}

.justify-center {
  justify-content: center
}

.justify-between {
  justify-content: space-between
}

.flex-1 {
  flex: 1 1 0%
}

.flex-auto {
  flex: 1 1 auto
}

.flex-shrink-0 {
  flex-shrink: 0
}

.flex-shrink {
  flex-shrink: 1
}

.order-1 {
  order: 1
}

.order-2 {
  order: 2
}

.font-sans {
  font-family: Montserrat, Muli, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
}

.font-serif {
  font-family: Georgia, Cambria, "Times New Roman", Times, serif
}

.font-300 {
  font-weight: 300
}

.font-400 {
  font-weight: 400
}

.font-500 {
  font-weight: 500
}

.font-600 {
  font-weight: 600
}

.font-700 {
  font-weight: 700
}

.font-light {
  font-weight: 300
}

.font-bold {
  font-weight: 700
}

.h-0 {
  height: 0
}

.h-8 {
  height: 0.8rem
}

.h-16 {
  height: 1.6rem
}

.h-20 {
  height: 2rem
}

.h-24 {
  height: 2.4rem
}

.h-32 {
  height: 3.2rem
}

.h-36 {
  height: 3.6rem
}

.h-40 {
  height: 4rem
}

.h-44 {
  height: 4.4rem
}

.h-48 {
  height: 4.8rem
}

.h-56 {
  height: 5.6rem
}

.h-64 {
  height: 6.4rem
}

.h-96 {
  height: 9.6rem
}

.h-200 {
  height: 20rem
}

.h-header {
  height: 12rem
}

.h-full {
  height: 100%
}

.text-6 {
  font-size: 0.6rem
}

.text-7 {
  font-size: 0.7rem
}

.text-9 {
  font-size: 0.9rem
}

.text-10 {
  font-size: 1rem
}

.text-11 {
  font-size: 1.1rem
}

.text-12 {
  font-size: 1.2rem
}

.text-13 {
  font-size: 1.3rem
}

.text-14 {
  font-size: 1.4rem
}

.text-15 {
  font-size: 1.5rem
}

.text-16 {
  font-size: 1.6rem
}

.text-18 {
  font-size: 1.8rem
}

.text-20 {
  font-size: 2rem
}

.text-24 {
  font-size: 2.4rem
}

.text-28 {
  font-size: 2.8rem
}

.text-32 {
  font-size: 3.2rem
}

.text-40 {
  font-size: 4rem
}

.text-96 {
  font-size: 9.6rem
}

.list-inside {
  list-style-position: inside
}

.m-8 {
  margin: 0.8rem
}

.my-4 {
  margin-top: 0.4rem;
  margin-bottom: 0.4rem
}

.mx-4 {
  margin-left: 0.4rem;
  margin-right: 0.4rem
}

.my-8 {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem
}

.mx-8 {
  margin-left: 0.8rem;
  margin-right: 0.8rem
}

.mx-12 {
  margin-left: 1.2rem;
  margin-right: 1.2rem
}

.my-16 {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem
}

.mx-24 {
  margin-left: 2.4rem;
  margin-right: 2.4rem
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}

.-mx-8 {
  margin-left: -0.8rem;
  margin-right: -0.8rem
}

.-mx-12 {
  margin-left: -1.2rem;
  margin-right: -1.2rem
}

.mt-0 {
  margin-top: 0
}

.mr-0 {
  margin-right: 0
}

.ml-2 {
  margin-left: 0.2rem
}

.mt-4 {
  margin-top: 0.4rem
}

.mr-4 {
  margin-right: 0.4rem
}

.mb-4 {
  margin-bottom: 0.4rem
}

.ml-4 {
  margin-left: 0.4rem
}

.mt-8 {
  margin-top: 0.8rem
}

.mr-8 {
  margin-right: 0.8rem
}

.mb-8 {
  margin-bottom: 0.8rem
}

.ml-8 {
  margin-left: 0.8rem
}

.ml-10 {
  margin-left: 1.0rem
}

.mt-12 {
  margin-top: 1.2rem
}

.mr-12 {
  margin-right: 1.2rem
}

.mb-12 {
  margin-bottom: 1.2rem
}

.mt-16 {
  margin-top: 1.6rem
}

.mb-16 {
  margin-bottom: 1.6rem
}

.ml-16 {
  margin-left: 1.6rem
}

.mt-20 {
  margin-top: 2rem
}

.mr-24 {
  margin-right: 2.4rem
}

.mb-24 {
  margin-bottom: 2.4rem
}

.ml-24 {
  margin-left: 2.4rem
}

.mt-32 {
  margin-top: 3.2rem
}

.mr-32 {
  margin-right: 3.2rem
}

.mb-32 {
  margin-bottom: 3.2rem
}

.mb-40 {
  margin-bottom: 4rem
}

.mr-48 {
  margin-right: 4.8rem
}

.mr-56 {
  margin-right: 5.6rem
}

.ml-56 {
  margin-left: 5.6rem
}

.ml-104 {
  margin-left: 10.4rem
}

.mt-auto {
  margin-top: auto
}

.ml-auto {
  margin-left: auto
}

.-mt-4 {
  margin-top: -0.4rem
}

.-ml-4 {
  margin-left: -0.4rem
}

.-mb-10 {
  margin-bottom: -1rem
}

.-mt-24 {
  margin-top: -2.4rem
}

.-mt-48 {
  margin-top: -4.8rem
}

[dir='ltr'] .ltr\:ml-4,[dir='ltr'].ltr\:ml-4 {
  margin-left: 0.4rem
}

[dir='ltr'] .ltr\:ml-8,[dir='ltr'].ltr\:ml-8 {
  margin-left: 0.8rem
}

[dir='rtl'] .rtl\:mr-4,[dir='rtl'].rtl\:mr-4 {
  margin-right: 0.4rem
}

[dir='rtl'] .rtl\:mr-8,[dir='rtl'].rtl\:mr-8 {
  margin-right: 0.8rem
}

.max-w-0 {
  max-width: 0
}

.max-w-136 {
  max-width: 13.6rem
}

.max-w-168 {
  max-width: 16.8rem
}

.max-w-320 {
  max-width: 32rem
}

.max-w-lg {
  max-width: 80rem
}

.max-w-full {
  max-width: 100%
}

.min-h-0 {
  min-height: 0
}

.min-h-40 {
  min-height: 4rem
}

.min-h-48 {
  min-height: 4.8rem
}

.min-h-64 {
  min-height: 6.4rem
}

.min-h-288 {
  min-height: 28.8rem
}

.min-h-320 {
  min-height: 32rem
}

.min-h-header {
  min-height: 12rem
}

.min-w-20 {
  min-width: 2rem
}

.min-w-40 {
  min-width: 4rem
}

.min-w-64 {
  min-width: 6.4rem
}

.min-w-96 {
  min-width: 9.6rem
}

.object-contain {
  object-fit: contain
}

.object-cover {
  object-fit: cover
}

.opacity-50 {
  opacity: 0.5
}

.opacity-75 {
  opacity: 0.75
}

.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.overflow-auto {
  overflow: auto
}

.overflow-hidden {
  overflow: hidden
}

.overflow-x-auto {
  overflow-x: auto
}

.overflow-x-hidden {
  overflow-x: hidden
}

.overflow-y-hidden {
  overflow-y: hidden
}

.overflow-y-scroll {
  overflow-y: scroll
}

.p-0 {
  padding: 0
}

.p-2 {
  padding: 0.2rem
}

.p-8 {
  padding: 0.8rem
}

.p-12 {
  padding: 1.2rem
}

.p-16 {
  padding: 1.6rem
}

.p-24 {
  padding: 2.4rem
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0
}

.px-0 {
  padding-left: 0;
  padding-right: 0
}

.py-4 {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem
}

.py-6 {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem
}

.py-8 {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem
}

.px-8 {
  padding-left: 0.8rem;
  padding-right: 0.8rem
}

.py-10 {
  padding-top: 1.0rem;
  padding-bottom: 1.0rem
}

.py-12 {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem
}

.px-12 {
  padding-left: 1.2rem;
  padding-right: 1.2rem
}

.py-16 {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem
}

.px-16 {
  padding-left: 1.6rem;
  padding-right: 1.6rem
}

.py-24 {
  padding-top: 2.4rem;
  padding-bottom: 2.4rem
}

.px-24 {
  padding-left: 2.4rem;
  padding-right: 2.4rem
}

.px-64 {
  padding-left: 6.4rem;
  padding-right: 6.4rem
}

.pl-0 {
  padding-left: 0
}

.pr-2 {
  padding-right: 0.2rem
}

.pb-4 {
  padding-bottom: 0.4rem
}

.pt-8 {
  padding-top: 0.8rem
}

.pb-8 {
  padding-bottom: 0.8rem
}

.pl-8 {
  padding-left: 0.8rem
}

.pl-10 {
  padding-left: 1.0rem
}

.pl-12 {
  padding-left: 1.2rem
}

.pr-16 {
  padding-right: 1.6rem
}

.pb-16 {
  padding-bottom: 1.6rem
}

.pl-16 {
  padding-left: 1.6rem
}

.pt-20 {
  padding-top: 2rem
}

.pt-24 {
  padding-top: 2.4rem
}

.pr-24 {
  padding-right: 2.4rem
}

.pb-24 {
  padding-bottom: 2.4rem
}

.pl-24 {
  padding-left: 2.4rem
}

.pr-28 {
  padding-right: 2.8rem
}

.pl-40 {
  padding-left: 4rem
}

.pr-48 {
  padding-right: 4.8rem
}

.pl-56 {
  padding-left: 5.6rem
}

.pb-64 {
  padding-bottom: 6.4rem
}

.pl-72 {
  padding-left: 7.2rem
}

.pl-80 {
  padding-left: 8rem
}

.pt-136 {
  padding-top: 13.6rem
}

.pointer-events-none {
  pointer-events: none
}

.static {
  position: static
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.sticky {
  position: -webkit-sticky;
  position: sticky
}

.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0
}

.top-0 {
  top: 0
}

.right-0 {
  right: 0
}

.bottom-0 {
  bottom: 0
}

.left-0 {
  left: 0
}

.resize {
  resize: both
}

.shadow-1 {
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 2px 1px -1px rgba(0,0,0,0.12)
}

.shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
}

.hover\:shadow-2:hover {
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 3px 1px -2px rgba(0,0,0,0.12)
}

.table-fixed {
  table-layout: fixed
}

.text-left {
  text-align: left
}

.text-center {
  text-align: center
}

.text-right {
  text-align: right
}

.text-black {
  --text-opacity: 1;
  color: #22292F;
  color: rgba(34, 41, 47, var(--text-opacity))
}

.text-white {
  --text-opacity: 1;
  color: #FFF;
  color: rgba(255, 255, 255, var(--text-opacity))
}

.text-secondaryMain {
  --text-opacity: 1;
  color: #1F242F;
  color: rgba(31, 36, 47, var(--text-opacity))
}

.text-secondaryLight {
  --text-opacity: 1;
  color: #7C808B;
  color: rgba(124, 128, 139, var(--text-opacity))
}

.text-errorMain {
  --text-opacity: 1;
  color: #E0115F;
  color: rgba(224, 17, 95, var(--text-opacity))
}

.hover\:text-secondaryMain:hover {
  --text-opacity: 1;
  color: #1F242F;
  color: rgba(31, 36, 47, var(--text-opacity))
}

.italic {
  font-style: italic
}

.uppercase {
  text-transform: uppercase
}

.lowercase {
  text-transform: lowercase
}

.capitalize {
  text-transform: capitalize
}

.normal-case {
  text-transform: none
}

.underline {
  text-decoration: underline
}

.no-underline {
  text-decoration: none
}

.hover\:underline:hover {
  text-decoration: underline
}

.align-top {
  vertical-align: top
}

.align-middle {
  vertical-align: middle
}

.visible {
  visibility: visible
}

.whitespace-no-wrap {
  white-space: nowrap
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.w-0 {
  width: 0
}

.w-16 {
  width: 1.6rem
}

.w-20 {
  width: 2rem
}

.w-24 {
  width: 2.4rem
}

.w-40 {
  width: 4rem
}

.w-48 {
  width: 4.8rem
}

.w-64 {
  width: 6.4rem
}

.w-72 {
  width: 7.2rem
}

.w-80 {
  width: 8rem
}

.w-104 {
  width: 10.4rem
}

.w-256 {
  width: 25.6rem
}

.w-xs {
  width: 32rem
}

.w-1\/2 {
  width: 50%
}

.w-1\/4 {
  width: 25%
}

.w-11\/12 {
  width: 91.666667%
}

.w-full {
  width: 100%
}

.z-0 {
  z-index: 0
}

.z-10 {
  z-index: 10
}

.z-99 {
  z-index: 99
}

.z-999 {
  z-index: 999
}

.z-9999 {
  z-index: 9999
}

.transform {
  --transform-translate-x: 0;
  --transform-translate-y: 0;
  --transform-rotate: 0;
  --transform-skew-x: 0;
  --transform-skew-y: 0;
  --transform-scale-x: 1;
  --transform-scale-y: 1;
  -webkit-transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
          transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y))
}

.transition-colors {
  transition-property: background-color, border-color, color, fill, stroke
}

.duration-200 {
  transition-duration: 200ms
}

.duration-300 {
  transition-duration: 300ms
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg)
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg)
  }
}

@keyframes ping {
  75%, 100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0
  }
}

@keyframes pulse {
  50% {
    opacity: .5
  }
}

@keyframes bounce {
  0%, 100% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1)
  }

  50% {
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1)
  }
}

.line-clamp-1 {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1
}

@media (min-width: 600px) {

  .sm\:block {
    display: block
  }

  .sm\:flex {
    display: flex
  }

  .sm\:flex-row {
    flex-direction: row
  }

  .sm\:order-1 {
    order: 1
  }

  .sm\:order-2 {
    order: 2
  }

  .sm\:h-288 {
    height: 28.8rem
  }

  .sm\:h-header {
    height: 12rem
  }

  .sm\:mb-0 {
    margin-bottom: 0
  }

  .sm\:mb-12 {
    margin-bottom: 1.2rem
  }

  .sm\:min-h-header {
    min-height: 12rem
  }

  .sm\:p-24 {
    padding: 2.4rem
  }

  .sm\:pr-0 {
    padding-right: 0
  }

  .sm\:pt-24 {
    padding-top: 2.4rem
  }

  .sm\:pl-24 {
    padding-left: 2.4rem
  }

  .sm\:w-288 {
    width: 28.8rem
  }

  .sm\:w-320 {
    width: 32rem
  }
}

@media (min-width: 960px) {

  .md\:flex {
    display: flex
  }

  .md\:h-40 {
    height: 4rem
  }

  .md\:mr-8 {
    margin-right: 0.8rem
  }

  .md\:p-24 {
    padding: 2.4rem
  }

  .md\:relative {
    position: relative
  }

  .md\:w-360 {
    width: 36rem
  }
}

@media (min-width: 1280px) {

  .lg\:h-32 {
    height: 3.2rem
  }

  .lg\:text-13 {
    font-size: 1.3rem
  }

  .lg\:text-16 {
    font-size: 1.6rem
  }

  .lg\:text-24 {
    font-size: 2.4rem
  }

  .lg\:text-40 {
    font-size: 4rem
  }

  .lg\:mr-6 {
    margin-right: 0.6rem
  }

  .lg\:mr-24 {
    margin-right: 2.4rem
  }

  .lg\:px-16 {
    padding-left: 1.6rem;
    padding-right: 1.6rem
  }

  .lg\:px-80 {
    padding-left: 8rem;
    padding-right: 8rem
  }

  .lg\:pt-80 {
    padding-top: 8rem
  }

  .lg\:pb-80 {
    padding-bottom: 8rem
  }

  .lg\:w-320 {
    width: 32rem
  }

  .lg\:w-1\/2 {
    width: 50%
  }
}

@media (min-width: 1920px) {

  .xl\:h-320 {
    height: 32rem
  }

  .xl\:mr-8 {
    margin-right: 0.8rem
  }
}