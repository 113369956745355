.editor-viewer pre {
  background-color: #eee;
  margin-bottom: 16px;
  padding: 16px;
  white-space: pre-wrap;
}

.editor-viewer table,
.editor-viewer tr,
.editor-viewer th,
.editor-viewer td {
  border: 1px solid #ccc;
}
.editor-viewer th {
  text-align: left;
  padding: 8px;
}
.editor-viewer td {
  padding: 12px 8px;
}
.editor-viewer thead tr {
  background-color: #eee;
}

/* video */
.editor-viewer__video,
.editor-viewer figure:not([class]) {
  max-width: 100%;
  width: 100%;
  text-align: center;
  margin: 16px auto 32px auto;
  background-color: #000;
}
.editor-viewer__video video,
.editor-viewer figure:not([class]) iframe {
  width: 100%;
  height: 240px;
}
.editor-viewer__video figcaption,
.editor-viewer figure:not([class]) figcaption {
  width: 100%;
  font-style: italic;
  background-color: #fff;
  padding-top: 8px;
}
.editor-viewer__video--stretched {
  width: 100% !important;
}
.editor-viewer__video--stretched video {
  height: auto !important;
}

@media screen and (min-width: 768px) {
  .editor-viewer__video,
  .editor-viewer figure:not([class]) {
    width: 640px;
  }
  .editor-viewer__video video,
  .editor-viewer figure:not([class]) iframe {
    height: 360px;
  }
}

@media screen and (min-width: 1024px) {
  .editor-viewer__video,
  .editor-viewer figure:not([class]) {
    width: 854px;
  }
  .editor-viewer__video video,
  .editor-viewer figure:not([class]) iframe {
    height: 480px;
  }
}
/* end video */

.editor-viewer__list {
  list-style: revert;
}

.editor-viewer__list > * {
  padding-top: 8px;
  padding-bottom: 8px;
}

.editor-viewer__blockquote{
  font-size: 1.2em;
  margin: 12px auto;
  font-style: italic;
  color: #555555;
  padding: 12px;
  border-left: 2px solid #7C808B;
  line-height: 1.6;
  position: relative;
  background: white;
}

.editor-viewer__blockquote::after{
  content: '';
}

.editor-viewer__blockquote footer {
  font-size: 13px;
  margin-top: 8px;
  margin-bottom: -12px;
}

.editor-viewer__checklist {
  padding-left: 32px;
  position: relative;
  margin-bottom: 12px;
}
.editor-viewer__checklist input {
  position: absolute;
  left: 0;
  visibility: hidden;
  margin-top: 0;
}
.editor-viewer__checklist input:before {
  content: '';
  visibility: visible;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: transparent;
  position: absolute;
  border: 1px solid #d0d0d0;
}
.editor-viewer__checklist input:after {
  content: '';
  visibility: hidden;
  display: inline-block;
  top: 7px;
  left: 6px;
  width: 9px;
  height: 4px;
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  background: transparent;
  transform: rotate(-45deg);
  position: absolute;
}
.editor-viewer__checklist input:checked:before {
  background-color: #388ae5;
  border-color: #388ae5;
}
.editor-viewer__checklist input:checked:after {
  visibility: visible;
}

/* image */
.editor-viewer__image img {
  max-width: 100%;
  width: 640px;
  height: auto;
  object-fit: cover;
}
.editor-viewer__image figcaption {
  width: 100%;
  padding-top: 8px;
}
.editor-viewer__image--withBackground {
  padding: 15px;
}

.editor-viewer__image--stretched img {
  width: 100% !important;
  height: auto;
}
@media screen and (min-width: 1024px) {
  .editor-viewer__image img {
    width: 854px;
  }
}
/* end image */

/* delimiter */
.editor-viewer__delimiter {
  border: 0;
  height: auto;
  line-height: 1.6em;
  width: 100%;
  text-align: center;
  padding: 0.4em 0;
}
.editor-viewer__delimiter::before {
  display: inline-block;
  content: "***";
  font-size: 30px;
  line-height: 65px;
  height: 30px;
  letter-spacing: 0.2em;
}
/* end delimiter */

/* columns + image */
.editor-viewer__columns .editor-viewer__image {
  aspect-ratio: 1 / 0.6;
  background-color: #f6f6f6;
}
.editor-viewer__columns .editor-viewer__image img {
  width: auto;
  height: 100%;
}
.editor-viewer__columns .editor-viewer__image figcaption {
  background-color: #fff;
}
.editor-viewer__columns .editor-viewer__image--withBackground {
  padding: 0;
  background-color: unset;
}
/* end columns + image */

/* header */
h1.ce-header {
  font-size: 41pt;
  font-weight: 400;
  color: #00A9E9;
}
h2.ce-header {
  font-size: 24pt;
  font-weight: 400;
  color: #231F20;
}
h3.ce-header {
  font-size: 18pt;
  font-weight: 600;
  color: #0075BC;
}
h4.ce-header {
  font-size: 12.5pt;
  font-weight: 700;
  text-transform: uppercase;
  color: #00254A;
}
h5.ce-header {
  font-size: 15pt;
  font-weight: 400;
}
/* end header */

/* paragraph */
.ce-paragraph * {
  font-family: inherit !important;
  font-size: inherit !important;
}
/* end paragraph */

/* button */
.editor-viewer__button a {
  
}
/* end button */